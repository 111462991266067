<template>
  <div class="">
    <b-container fluid="xl">
      <div class="px-xl-5 px-md-2 mx-lg-2">
        <b-row cols-md="2" cols="1">
          <b-col class="text-start pe-lg-5 pe-1"
            ><div>
              <img
                src="../assets/catalogue.png"
                class="mt-md-n5 book-img mx-auto text-center d-flex"
              />
              <h1 class="display-4 fw-stronger">
                Veiliger wonen in uw eigen huis
              </h1>
              <h5 class="l-height">
                Als u meer moeite hebt met traplopen, kan een traplift een
                uitkomst zijn. U wordt veilig en comfortabel naar boven en
                beneden gebracht. In onze gratis traplift brochure kunt u op uw
                gemak alles lezen over trapliften. Met deze brochure krijgt u
                een beeld van de mogelijkheden en modellen, maar ook de aankoop
                en financiering.
              </h5>
              <h5 class="l-height fw-stronger">
                Vraag onze brochure aan en ontdek:
              </h5>
              <ol>
                <li class="h5 l-height mb-0">Tips en adviezen</li>
                <li class="h5 l-height mb-0">
                  Grootste assortiment in merken en modellen
                </li>
                <li class="h5 l-height mb-0">
                  Trapliften in iedere prijsklasse
                </li>
                <li class="h5 l-height mb-0">
                  Vul hiernaast uw gegevens in en ontvang de gratis traplift
                  brochure
                </li>
              </ol>
            </div></b-col
          >
          <b-col
            ><h1 class="fw-bold mt-5">
              Vergelijk 17 trapliften van 6 fabrikanten
            </h1>
            <b-button class="s-btn btn-md px-md-5 px-3 fw-bold">
              Vraag de gratis en vrijblijvende brochure aan </b-button
            ><b-card>
              <div class="formSection d-flex flex-column">
                <h3>
                  <span class="fw-bold">JA,</span> stuur mij de gratis traplift
                  brochure
                </h3>
                <div class="m-auto">
                  <img
                    v-if="profId != null"
                    referrerpolicy="no-referrer-when-downgrade"
                    :src="`https://republish.verbeterthuis.nl/m/5350/0cc389825323/?event=6256&unique_conversion_id=${profId}`"
                    style="width: 1px; height: 1px; border: 0px"
                  />
                  <!-- <h3>{{ profId }}</h3>   -->
                  <p class="my-4 text-center">
                    Bedankt voor uw aanvraag. Bekijk nu uw onze traplift
                    brochure!
                    <a
                      href="https://s3-eu-west-1.amazonaws.com/cdn.mobilae.be/emag/be_traplift_brochure/index.html"
                      target="_blank"
                      >Bekijk nu</a
                    >
                  </p>
                </div>
              </div>
            </b-card></b-col
          >
        </b-row>
      </div>
    </b-container>
    <div class="hero bg-color py-4 py-md-5 mt-4">
      <b-container fluid="xl">
        <div class="px-xl-5 px-md-2 mx-lg-2">
          <b-row cols-md="2" cols="1" class="align-items-center">
            <b-col class="text-start"
              ><div>
                <h1 class="fw-stronger">
                  Vind de geschikte traplift voor uw situatie
                </h1>
                <h5 class="l-height">
                  Wilt u weer gemakkelijk naar boven of beneden? Met een Mobilae
                  traplift blijft u mobiel, veilig en zelfstandig in huis. Als
                  onafhankelijk adviseur aan uw zijde helpen wij u bij het
                  kiezen van de juiste traplift. Ons uitgebreide assortiment
                  bevat uitsluitend hoogwaardige modellen van topfabrikanten.
                  Samen met u lopen we de door verschillende mogelijkheden. We
                  bekijken welk model van welke fabrikant het beste past bij uw
                  persoonlijke woonsituatie, uw wensen en behoeften. Met 30 jaar
                  ervaring in heel Europa zorgen we ervoor dat elke stap op weg
                  naar uw nieuwe traplift eenvoudig en goed doordacht is. Van
                  advies tot installatie en klantenservice. Onze unieke
                  garanties en jaarlijks onderhoud zorgen ervoor dat u jarenlang
                  tevreden bent.
                </h5>
              </div>
            </b-col>
            <b-col>
              <div>
                <img src="../assets/afspraak.png" class="w-100 rounded" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AfterFormSubmited",
  computed: mapGetters(["profId"]),
};
</script>

<style lang="scss">
$enable-negative-margins: true;
.formSection {
  height: 684px;
}

.custom-select {
  appearance: revert !important;
}
.form-control {
  padding: 0.75rem 0.75rem !important;
}
.s-btn {
  padding: 1rem !important;

  background-color: #ff5a00 !important;
  border: 0 !important;
  border-radius: 15px !important;
  @media (min-width: 767px) and (max-width: 990px) {
    padding: 1rem !important;
  }
}
.book-img {
  width: 100%;
  @media (max-width: 767.9px) {
    width: 50% !important;
  }
}
.card {
  margin: 50px 0;
  border-radius: 10px !important;
}
.card-body {
  background-color: #50a89e !important;
  color: white;
  padding: 50px !important;
  border-radius: 7px;
  @media (max-width: 990px) {
    padding: 30px 15px !important;
  }
}
.opacity {
  opacity: 0.6;
}
label {
  padding: 7px 0;
}
@media (min-width: 768px) {
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
}
.bg-color {
  background-color: #b9e6ff !important;
}
.l-height {
  line-height: 1.9 !important;
}
</style>
